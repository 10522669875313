<template>
   <div>
      <page-header v-if="hasCreatePermission" btnNew="ACTIONS.NEW" pageTitle="PROPERTY_TYPES.INDEX_TITLE"
                   @newType="setRegisterModal"/>
      <page-header v-if="!hasCreatePermission" pageTitle="PROPERTY_TYPES.INDEX_TITLE"/>

      <list-component :items="items.data" v-if="items && items.data" :haveDetails="false">
         <template v-slot:principal-info="{items}">
            <div class="list-primary hide-text">{{ items.iten.name }}</div>
         </template>
         <template v-slot:dropdown-options="items">
            <div class="dropdown-item pointer text-white" data-target="#customModalTwo" data-toggle="modal" data-cy="edit-property-type"
                 @click="setEditModal(items.iten)" v-if="hasUpdatePermission && hasShowPermission">
               <i class="icon-edit mr-2 font-15"/>{{ t('ACTIONS.EDIT') }}
            </div>
            <div class="dropdown-item pointer text-white" data-cy="delete-property-type" @click="showAlert(items.iten.id)" v-if="hasDeletePermission">
               <i class="icon-delete mr-2 font-15"/>{{ t('ACTIONS.DELETE') }}
            </div>
         </template>
      </list-component>

      <pagination-component v-if="items && items.data" :items="items" :to="'/tipo-propriedade'"
                            @changePage="index($event)"></pagination-component>

      <div class="card" v-if="items && !items.data">
         <div class="card-header"></div>
         <div class="card-body pt-0">
            <div class="text-center">
               <h5>{{ t('GENERAL.MSG.NO_REGISTER', {name: t('PROPERTY_TYPES.PROPERTIES_TYPES'), os: 'os'}) }}</h5>
            </div>
         </div>
      </div>

      <modal :titleModal="modalTitle">
         <template v-slot:title>{{ t(modalTitle) }}</template>
         <form @submit.prevent="create">
            <div class="modal-body">
               <div class="form-group">
                  <label for="inputName" class="required">{{ t('GENERAL.NAME') }}: </label>
                  <input id="name" v-model="name" class="form-control" name="name" placeholder="Digite o nome"
                         type="text" @keyup="inputName(name)">
                  <div v-if="isNameInvalid" class="validation">{{ t(msgError) }}</div>
               </div>
            </div>
            <div class="modal-footer2">
               <div class="justify-content-left">
                  <h5 class="ml-2" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
               </div>
               <div class="ml-custom">
                  <button data-cy="property_type_create_close" class="btn btn-secondary" data-dismiss="modal"
                          type="button">
                     {{ t('ACTIONS.CLOSE') }}
                  </button>
                  <button data-cy="property_type_create_save" class="btn btn-primary ml-2" type="submit">
                     {{ t('ACTIONS.SAVE') }}
                  </button>
               </div>
            </div>
         </form>
      </modal>
   </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import {defineComponent} from 'vue';
import Modal from "@/components/modal";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import Permission from '@/components/permission.vue';
import propertyTypes from '@/services/propertyTypes';
import pageHeader from "@/components/layouts/pageHeader";
import ListComponent from '@/components/layouts/listComponent.vue';
import PaginationComponent from "@/components/layouts/PaginationComponent";

export default defineComponent({
   name: 'indexPropertyTypes',
   mixins: [Permission, Validate],
   components: {
      Modal,
      pageHeader,
      ListComponent,
      PaginationComponent,
   },
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {t, toast}
   },
   data() {
      return {
         msg: '',
         id: null,
         type: {},
         resp: null,
         items: null,
         isAlert: true,
         modalTitle: '',
         permissions: [],
         isNewType: false,
         hasShowPermission: false,
         hasIndexPermission: false,
         hasCreatePermission: false,
         hasUpdatePermission: false,
         hasDeletePermission: false,

      }
   },
   mounted() {
      this.$store.commit('changeLoading', true);

      this.index();
      this.startPermissons();
   },

   methods: {
      async index(page = 1) {
         await propertyTypes.index(page).then(resp => {
            this.items = resp.data;
         }).catch(error => {
            this.errorMsg(error)
         });

         this.$store.commit('changeLoading', false);
      },

      updatePaginate(page) {
         this.updateItemsFromPaginate(page, propertyTypes)
      },

      setEditModal(iten) {
         this.id = iten.id;
         this.name = iten.name;
         this.modalTitle = 'PROPERTY_TYPES.MODAL_TITLE.EDIT';
      },

      setRegisterModal() {
         this.id = null;
         this.name = null
         this.modalTitle = 'PROPERTY_TYPES.MODAL_TITLE.ADD';
      },

      startPermissons() {
         this.hasShowPermission = this.validatePermissions('PropertiesType:show');
         this.hasCreatePermission = this.validatePermissions('PropertiesType:create');
         this.hasUpdatePermission = this.validatePermissions('PropertiesType:update');
         this.hasDeletePermission = this.validatePermissions('PropertiesType:delete');
      },

      showAlert(id) {
         this.$swal.fire({
            title: this.t('GENERAL.MSG.REALLY_WANT_DELETE'),

            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: `Excluir`,
            cancelButtonText: 'Cancelar'
         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.commit('changeLoading', true);

               propertyTypes.delete(id).then(resp => {
                  document.getElementById('closeX').click();
                  this.resp = resp;
                  this.index();
                  this.$store.commit('changeLoading', false);
                  this.toast.success(resp.data.validations.message);

               }).catch(error => {
                  this.$store.commit('changeLoading', false);
                  this.errorMsg(error)
               });
            }
         });
      },

      create() {
         this.startForm();

         if (this.checkForm()) {
            this.$store.commit('changeLoading', true);

            if (this.type.id) {

               propertyTypes.update(this.type).then(resp => {
                  document.getElementById('closeX').click();
                  this.resp = resp;
                  this.index();
                  this.$store.commit('changeLoading', false);
                  this.toast.success(this.t('GENERAL.MSG.UPDATE_SUCCESS'));

               }).catch(error => {
                  this.$store.commit('changeLoading', false);
                  this.errorMsg(error);
               });

            } else {

               propertyTypes.create(this.type).then(resp => {
                  document.getElementById('closeX').click();
                  this.resp = resp;
                  this.index();
                  this.$store.commit('changeLoading', false);
                  this.toast.success(this.t('GENERAL.MSG.REGISTER_SUCCESS'));

               }).catch(error => {
                  this.$store.commit('changeLoading', false);
                  this.errorMsg(error);
               });
            }
         }
      },

      checkForm() {
         this.nameValidate(this.name);

         if (this.invalid.length && this.invalid.length > 0) {
            return false;
         }
         return true;
      },


      startForm() {
         this.type = {
            id: this.id,
            name: this.name,
         }
         this.invalid = [];
      }
   }
});
</script>

<style>
.zIndex {
   z-index: 100000000000 !important;
}
</style>
